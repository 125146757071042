import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/common/dictionary"

export default {
	...commonApi(moduleName),
	// 查询列表
	apiGetPage: data =>
		request("/common/dictionary/open", {
			method: "get",
			params: data
		}),
	// 查询列表
	apiGetPageWithoutToken: data =>
		request("/common/dictionary", {
			method: "get",
			params: data
		}),
	// 获取字典信息
	apiGet: data => request(`/common/dictionary/open/${data.id}`),

	// 获取字典数据
	apiGetData: id => request(`/common/dictionary/${id}`),

	// 获取字典有效列表（放开权限）
	apiGetOpenAccessData: data =>
		request("/common/dictionaries/open-access", {
			method: "get",
			params: data
		}),

	// 排序
	apiDictionarySort: data =>
		request("/common/dictionary/dictionary-sort", {
			method: "put",
			body: data
		})
}
